import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">


<path d="M0 2500 l0 -2500 2500 0 2500 0 0 2500 0 2500 -2500 0 -2500 0 0
-2500z m2797 1591 c203 -42 391 -118 553 -223 111 -72 147 -102 254 -206 242
-237 399 -538 462 -885 26 -139 23 -416 -5 -572 -118 -657 -604 -1156 -1262
-1296 -150 -32 -427 -33 -585 -1 -378 75 -701 271 -936 565 -238 299 -357 643
-358 1034 -1 615 345 1178 889 1449 155 76 359 135 551 158 85 10 340 -4 437
-23z"/>
<path d="M2285 4005 c-636 -103 -1130 -595 -1246 -1240 -18 -104 -20 -145 -16
-310 6 -209 17 -276 74 -445 172 -512 592 -888 1116 -1002 134 -29 427 -32
553 -6 447 93 794 345 1028 747 87 150 164 384 187 573 22 181 -5 465 -62 639
-82 250 -197 439 -383 625 -212 212 -428 331 -724 400 -137 32 -388 41 -527
19z m312 -232 c25 -65 23 -84 -3 -29 -10 20 -21 36 -24 36 -4 0 -17 -23 -30
-51 -29 -60 -123 -152 -175 -169 -43 -14 -44 -25 -4 -39 75 -26 199 -155 186
-193 -2 -7 -13 5 -24 27 -41 78 -136 151 -220 170 -44 9 -41 19 10 32 97 27
199 124 234 224 10 27 20 49 23 49 3 0 15 -26 27 -57z m180 -196 c80 -37 81
-43 19 -72 -86 -41 -151 -105 -193 -194 -33 -68 -38 -73 -44 -51 -12 41 -11
56 2 43 9 -9 18 1 37 37 32 62 102 131 171 169 l54 29 -46 20 c-46 21 -137 96
-137 115 0 5 20 -9 45 -32 24 -22 66 -51 92 -64z m-467 -268 c0 -11 -4 -18 -8
-15 -4 3 -16 -11 -26 -29 -11 -19 -34 -45 -52 -57 -40 -28 -41 -31 -9 -46 13
-6 37 -29 53 -51 22 -30 32 -37 41 -28 15 15 15 -4 -1 -38 l-12 -27 -22 50
c-17 38 -35 59 -74 85 -47 32 -50 36 -30 42 36 11 98 74 110 111 12 36 30 37
30 3z m41 -63 c16 -19 46 -40 64 -46 41 -14 45 -25 10 -34 -13 -3 -40 -20 -60
-37 -19 -17 -35 -27 -35 -21 0 13 58 62 73 62 6 0 -12 21 -41 46 -28 25 -52
50 -52 55 0 16 10 10 41 -25z m-684 -492 c4 -11 -23 -45 -90 -112 -145 -147
-208 -251 -163 -268 22 -8 64 19 141 91 78 73 145 106 170 85 11 -9 19 -10 27
-2 14 14 38 4 38 -16 0 -8 -20 -34 -44 -58 -52 -50 -68 -75 -56 -93 20 -34
121 37 175 123 28 44 56 58 70 36 3 -6 -19 -36 -49 -68 -56 -59 -68 -88 -42
-98 24 -9 46 9 95 79 68 97 218 292 235 306 8 7 20 10 26 6 21 -13 9 -38 -41
-82 -110 -101 -226 -263 -213 -298 12 -30 50 -15 119 44 17 14 10 4 -15 -22
-69 -75 -136 -100 -147 -57 -5 20 -5 20 -30 0 -31 -24 -78 -26 -87 -4 -3 8 1
30 10 47 l16 32 -33 -33 c-63 -64 -135 -82 -133 -34 1 27 -8 28 -30 3 -23 -26
-72 -36 -91 -21 -8 7 -15 25 -15 40 l0 28 -37 -35 c-61 -55 -114 -59 -123 -9
-5 27 -6 28 -19 12 -23 -28 -71 -48 -97 -42 -20 5 -24 13 -24 41 0 50 69 129
145 165 49 24 59 24 79 5 13 -14 25 -3 106 105 88 116 114 138 127 104z m1131
-5 c1 -8 -40 -58 -91 -111 -127 -130 -188 -224 -168 -256 12 -20 53 -6 103 35
35 29 33 25 -13 -20 -55 -55 -94 -75 -124 -63 -21 8 -19 55 6 95 18 31 155
211 230 304 31 38 51 44 57 16z m589 5 c4 -11 -22 -44 -86 -107 -115 -116
-222 -260 -182 -245 5 2 33 30 62 63 64 74 94 97 116 93 31 -6 25 -32 -22
-103 -47 -72 -47 -93 -1 -81 16 4 37 23 52 49 15 23 40 59 56 80 28 35 28 37
9 37 -12 0 -21 5 -21 10 0 6 13 10 29 10 23 0 36 11 71 58 51 68 67 82 86 75
25 -9 16 -27 -43 -80 -55 -50 -57 -53 -30 -53 15 0 27 -5 27 -11 0 -6 -15 -9
-34 -8 -39 4 -54 -11 -100 -93 -51 -92 3 -106 93 -24 l35 31 -30 -36 c-64 -79
-149 -115 -161 -69 -5 19 -7 19 -49 -6 -35 -20 -47 -23 -63 -14 -39 21 -26 62
47 144 50 56 22 60 -34 5 -25 -24 -64 -68 -87 -96 -41 -52 -63 -63 -83 -43 -7
7 -29 0 -68 -24 -32 -19 -61 -36 -65 -38 -3 -2 -26 -29 -50 -61 -93 -122 -172
-162 -179 -90 -2 25 5 40 33 71 31 35 126 91 176 106 13 3 66 76 56 76 -2 0
-18 -11 -37 -25 -41 -30 -85 -33 -100 -6 -5 11 -7 28 -3 38 5 12 -9 6 -44 -20
-74 -54 -133 -45 -102 16 35 68 106 167 121 167 35 0 28 -22 -26 -78 -54 -57
-67 -84 -46 -97 20 -12 52 7 142 86 91 78 138 103 168 88 8 -4 28 -5 43 -2 41
8 35 -19 -16 -75 -50 -53 -111 -141 -104 -148 9 -10 58 17 98 53 23 21 90 101
147 178 149 199 162 215 178 215 8 0 17 -7 21 -16z m-1390 -120 c3 -8 0 -27
-6 -40 -14 -32 -54 -30 -59 2 -6 40 51 73 65 38z m871 -10 c3 -24 -21 -54 -44
-54 -21 0 -28 27 -15 56 14 32 54 30 59 -2z m-508 -93 c0 -9 -14 -30 -31 -47
-39 -37 -123 -153 -116 -160 12 -13 52 11 115 69 60 54 62 56 22 12 -65 -71
-80 -83 -130 -106 -30 -15 -57 -38 -79 -70 -53 -77 -127 -141 -159 -137 -21 2
-28 9 -30 31 -6 54 87 141 182 169 38 12 83 65 91 110 2 11 18 35 35 54 16 19
30 38 30 43 0 4 11 20 25 35 26 28 45 27 45 -3z m-140 3 c0 -9 -17 -32 -37
-52 -48 -45 -73 -81 -66 -98 7 -19 43 -18 71 2 13 9 26 14 29 11 2 -3 -15 -20
-38 -37 -51 -36 -102 -39 -107 -5 -4 30 105 195 129 195 11 0 19 -7 19 -16z
m1420 -3 c0 -10 -14 -29 -31 -42 -40 -31 -79 -87 -72 -105 8 -21 47 -17 79 8
39 31 59 54 95 111 19 29 38 47 50 47 30 0 23 -27 -19 -73 -44 -47 -114 -148
-109 -154 11 -10 64 22 115 69 32 30 44 38 27 18 -50 -57 -88 -89 -131 -109
-25 -12 -62 -46 -97 -90 -99 -122 -168 -155 -175 -84 -6 57 62 113 217 182 7
4 25 26 39 51 l27 45 -42 -34 c-30 -24 -53 -34 -80 -35 -36 -1 -38 1 -36 27 3
39 101 187 124 187 12 0 19 -7 19 -19z m-2001 -721 c32 -57 36 -72 33 -122 -2
-43 -7 -58 -17 -58 -11 0 -15 13 -15 50 0 38 -9 68 -35 120 -40 80 -41 83 -19
78 9 -2 33 -32 53 -68z m101 63 c-1 -5 -8 -19 -16 -33 -8 -14 -13 -17 -10 -7
3 10 6 25 6 33 0 8 5 14 10 14 6 0 10 -3 10 -7z m215 -55 c15 -14 27 -35 28
-53 l2 -30 -77 -3 c-62 -2 -78 -6 -78 -18 0 -21 27 -71 42 -77 9 -4 8 -6 -3
-6 -20 -1 -58 34 -70 67 -36 95 81 184 156 120z m166 11 c-14 -5 -31 -15 -38
-21 -10 -8 -13 -7 -13 5 0 16 19 24 55 25 17 1 16 -1 -4 -9z m77 -5 c20 -14
22 -23 22 -105 0 -72 -3 -89 -15 -89 -8 0 -15 5 -15 11 0 6 -7 8 -17 5 -16 -6
-16 -5 -1 11 15 17 17 42 9 150 0 12 -9 24 -18 26 -14 4 -14 5 -3 6 9 0 26 -6
38 -15z m128 2 c3 -8 6 -55 7 -105 2 -81 0 -91 -15 -91 -16 0 -18 12 -18 105
0 65 4 105 10 105 6 0 13 -6 16 -14z m114 -1 c0 -10 -10 -15 -29 -15 -20 0
-35 -8 -45 -22 -9 -12 -16 -17 -16 -10 0 20 47 62 70 62 11 0 20 -7 20 -15z
m90 -15 c0 -16 5 -30 10 -30 7 0 7 -6 0 -20 -6 -10 -10 -23 -9 -27 1 -4 1 -29
0 -55 -1 -36 -5 -48 -16 -48 -12 0 -15 18 -15 105 0 87 3 105 15 105 10 0 15
-10 15 -30z m138 18 c8 -8 12 -46 12 -106 0 -84 -2 -93 -17 -90 -15 3 -18 16
-19 85 -2 101 -7 116 -36 108 -18 -5 -20 -4 -8 4 20 14 53 14 68 -1z m233 0
c1 -7 0 -51 -1 -97 -1 -61 2 -86 12 -92 10 -7 10 -9 1 -9 -8 0 -13 -13 -13
-30 0 -20 -5 -30 -15 -30 -13 0 -15 22 -15 135 0 113 2 135 15 135 8 0 15 -6
16 -12z m118 -12 c52 -56 30 -161 -39 -184 l-32 -12 31 29 c28 26 31 35 31 85
0 63 -26 102 -61 93 -10 -3 -17 -1 -13 4 11 18 61 9 83 -15z m122 -23 c5 -166
5 -163 -13 -163 -16 0 -18 12 -18 105 0 87 3 105 15 105 11 0 15 -12 16 -47z
m109 32 c0 -10 -10 -15 -29 -15 -20 0 -35 -8 -45 -22 -9 -12 -16 -17 -16 -10
0 20 47 62 70 62 11 0 20 -7 20 -15z m223 -9 c31 -29 42 -64 33 -110 -8 -40
-56 -86 -89 -85 l-22 0 21 9 c36 14 54 50 54 105 0 63 -20 95 -60 95 -23 0
-33 -7 -45 -30 -22 -43 -19 -112 6 -144 16 -21 17 -26 5 -26 -16 0 -52 41 -62
72 -10 33 6 89 33 114 35 33 91 32 126 0z m-1333 -44 c-69 -34 -78 -121 -15
-132 29 -6 29 -6 6 -12 -57 -16 -99 32 -77 86 11 28 83 77 110 76 6 -1 -5 -9
-24 -18z m-176 -129 c-32 -23 -46 -14 -17 11 16 14 30 20 32 14 2 -6 -4 -17
-15 -25z"/>
<path d="M1665 2532 c-71 -44 -120 -135 -85 -157 22 -14 102 57 136 120 17 31
17 37 4 45 -20 13 -20 13 -55 -8z"/>
<path d="M1355 2522 c-67 -43 -109 -118 -79 -143 12 -9 20 -9 41 2 30 16 115
127 110 143 -6 19 -40 17 -72 -2z"/>
<path d="M2965 2526 c-37 -16 -92 -77 -101 -111 -14 -58 30 -60 90 -5 47 43
72 90 62 115 -7 18 -12 18 -51 1z"/>
<path d="M2801 2244 c-28 -15 -59 -37 -67 -50 -17 -25 -16 -55 3 -61 13 -5 93
66 124 109 26 36 10 37 -60 2z"/>
<path d="M2070 2244 c-51 -28 -80 -58 -80 -84 0 -55 78 -7 148 92 11 16 11 18
-5 17 -10 0 -38 -11 -63 -25z"/>
<path d="M3482 2239 c-29 -16 -58 -41 -64 -54 -14 -31 -4 -57 19 -48 22 7 87
68 115 106 18 25 18 27 1 27 -10 0 -42 -14 -71 -31z"/>
<path d="M1851 1804 c-12 -15 -21 -35 -21 -45 0 -16 8 -19 60 -19 60 0 60 0
60 29 0 60 -62 82 -99 35z"/>
</g>
</svg>



    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
